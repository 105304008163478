<template>
  <a-modal
    v-model="addMediaVisible"
    title="添加媒体"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="alliance_addapp_modal"
    :width="1000"
  >
    <a-spin class="left_main" :spinning="spinning">
      <div class="adplace_left_search">
        <a-input class="m_input_cust" placeholder="请输入联盟名称搜索" v-model.trim="searchKey">
          <a-icon slot="suffix" type="search" />
        </a-input>
      </div>
      <div class="adplace_left_main" :style="{ overflowY: filterList.length > 0 ? 'auto' : '' }">
        <a-menu v-if="filterList.length > 0" :selectedKeys="currentApp" type="inner" @click="handleSelect">
          <a-menu-item v-for="item in filterList" :key="item.channelId">
            <div class="adplace_main_menu_item" :title="item.name">
              <div class="img">
                <img :src="item.iconUrl ? item.iconUrl : '/images/app-none.png'" alt="" />
              </div>
              <div class="desc">
                {{ item.name }}
              </div>
            </div>
          </a-menu-item>
        </a-menu>
        <m-empty v-else imgHeight="100px">
          <template #description> 暂无媒体联盟 </template>
        </m-empty>
      </div>
    </a-spin>
    <div class="content">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <!-- 联盟名称 -->
        <!-- <div class="content_item">
          <img :src="query.iconUrl ? query.iconUrl : '/images/app-none.png'" alt="" />
          <span>{{ query.name }}</span>
        </div> -->
        <!-- 联盟类型 -->
        <div class="content_item" style="margin-top: 10px">
          <p>联盟类型</p>
          <a-input disabled v-model.trim="query.sourceType" placeholder="" style="width: 460px" />
        </div>
        <!-- 所属行业分类 -->
        <div class="content_item">
          <p>所属行业分类</p>
          <a-input disabled v-model.trim="query.tradeName" placeholder="" style="width: 460px" />
        </div>
        <!-- 近七日流量请求数 -->
        <div class="content_item" style="margin: 30px 0">
          <p>近七日请求数</p>
          <a-spin style="width: 68%; margin-left: 1%" :spinning="spinnings">
            <div class="chart" ref="chart"></div>
          </a-spin>
        </div>
        <p>媒体介绍</p>
        <!-- 素材 -->
        <div style="margin-bottom: 10px">
          <div class="img_box">
            <img
              v-for="(item, index) in query.introductionImg"
              :key="index"
              class="a_image"
              :src="item ? item : '/images/app-none.png'"
              alt=""
            />
          </div>
        </div>
        <!-- 基本内容 -->
        <a-textarea
          style="width: 600px; margin-bottom: 20px"
          v-model="query.description"
          disabled
          :rows="6"
          placeholder=""
        />
        <!-- 计费模式 -->
        <div class="content_item">
          <span style="margin-right: 25px">计费模式</span>
          <a-radio-group v-model="os">
            <a-radio value="0"> 竞价 </a-radio>
          </a-radio-group>
        </div>
        <!-- 流量状态 -->
        <div class="content_item" v-if="role !== 'SUPER_ADMIN'">
          <span style="margin-right: 25px">流量状态</span>
          <a-radio-group v-model="query.trafficSts">
            <a-radio value="A"> 开启 </a-radio>
            <a-radio value="S"> 关闭 </a-radio>
          </a-radio-group>
        </div>
      </a-form-model>
    </div>
    <template slot="footer">
      <a-button key="back" @click="handleCancel"> 取消 </a-button>
      <a-button key="submit" type="primary" @click="handleSubmit" :disabled="loading"> 确定 </a-button>
    </template>
  </a-modal>
</template>

<script>
import { getPool, mediaLeagueSts } from '@/api/media'
import { mapState } from 'vuex'
import options from './options'
import * as echarts from 'echarts'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      form: this.$form.createForm(this),
      query: {},
      rules: {},
      industry: {},
      pid: [],
      currentApp: [],
      searchKey: '',
      options,
      myEcharts: null,
      spinning: false,
      spinnings: false,
      loading: false,
      filterLists: [],
      os: '0'
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    appInfo: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {},
  created () {
    this.getPool() // 获取联盟数据
  },
  mounted () {},
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl,
      role: (state) => state.user.roles[0]
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addMediaVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    filterList () {
      return this.filterLists.filter((item) => item.name.includes(this.searchKey))
    }
  },
  methods: {
    // 处理图片
    getCreativeUrl (url) {
      return url ? this.resourceUrl + url : null
    },
    // 初始化echart
    initChart () {
      setTimeout(() => {
        this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
        this.myEcharts.setOption(this.options)
        this.spinnings = false
      }, 500)
    },
    // echart赋值
    async getChartsData (arr) {
      this.options.xAxis.data = arr.map((item) => item.date)
      this.options.series[0].data = arr.map((item) => item.request)
      this.initChart()
    },
    // 选择媒体
    handleSelect ({ key }) {
      this.spinnings = true
      this.currentApp = [key]
      this.query = this.filterList.find((item) => item.channelId === key)
      this.getChartsData(this.query.sevenDaysRequest)
      this.initChart()
    },
    // 取消弹窗
    handleCancel () {
      this.addMediaVisible = false
    },
    // 获取媒体联盟弹窗数据
    async getPool () {
      const query = this.appInfo.channelId || null
      this.spinning = true
      this.spinnings = true
      const res = await getPool(query)
      if (res.code === 200) {
        this.filterLists = res.data
        const firstApp = res.data.length > 0 ? res.data[0] : ''
        this.currentApp = firstApp && [firstApp.channelId]
        this.query = res.data[0] || {}
        this.spinning = false
        this.spinnings = false
        firstApp && this.getChartsData(res.data[0].sevenDaysRequest)
      }
    },
    // 确定
    handleSubmit () {
      this.loading = true
      this.spinning = true
      this.spinnings = true
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { channelId = '', trafficSts = '' } = this.query
          const resp = await mediaLeagueSts({
            appId: this.appInfo.appId,
            channelId,
            trafficSts
          })
          if (resp.code === 200) {
            this.loading = false
            this.$message.success('添加成功!')
            this.$emit('changeAppInfo', 'add')
            this.addMediaVisible = false
            this.spinning = false
            this.spinnings = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .ant-modal-wrap .ant-modal .ant-modal-body {
    padding: 10px;
    display: flex;
  }
  .ant-empty {
    margin: 0;
    margin-right: 8px;
    border-radius: 5px;
  }
  .alliance_addapp_modal {
    position: relative;
    .left_main {
      flex-grow: 1;
      height: 450px;
      background-color: #fff;
      margin-right: 8px;
      border-radius: 5px;
      padding-bottom: 20px;
      .adplace_left_search {
        height: 50px;
        padding: 20px 16px 10px;
        background-color: #fff;
        border-radius: 5px;
        margin-bottom: 10px;
      }
      .adplace_left_main {
        border-radius: 5px;
        box-sizing: border-box;
        flex-grow: 1;
        height: 380px;
        .ant-menu.ant-menu-vertical.ant-menu-root.ant-menu-light {
          border: none;
          .ant-menu-item {
            height: 50px;
            line-height: 50px;
            color: #999999;
            &.ant-menu-item-selected {
              background-color: fade(@primary-color,  5%);
              color: @primary-color;
              font-weight: 600;
              &::before {
                content: '';
                height: 100%;
                position: absolute;
                left: 0;
                border-radius: 5px;
                border-left: 3px solid @primary-color;
              }
            }
          }
        }
        .adplace_main_menu_item {
          display: flex;
          .img {
            width: 30px;
            height: 30px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .desc {
            margin-left: 4px;
            border: 1px;
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .content {
      height: 450px;
      width: 80%;
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
      overflow-y: auto;
      .content_item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        p {
          width: 120px;
          text-align: left;
          margin-right: 10px;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          margin-right: 9px;
        }
        .chart {
          width: 100%;
          height: 100px;
        }
      }
      .img_box {
        display: flex;
        flex-flow: row wrap;
        .a_image {
          width: 29%;
          height: 60%;
          margin-right: 10px;
          margin-bottom: 10px;
          background: #f3f4f9;
          border-radius: 10px;
        }
      }
    }
  }
  .spin {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
